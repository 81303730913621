/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment, useState } from "react";
import SEO from "./SEO";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import Breadcrumbs from "./Breadcrumbs";
import Footer from "./Footer";
import {
  DESKTOP_MAX_WIDTH,
  DESKTOP_HORIZONTAL_PADDING,
  MOBILE_HORIZONTAL_PADDING,
} from "../utils/Constants";

const MENU_TRANSITION_TIME = 300;

const links = [
  {
    path: "/activities",
    title: "Activities",
  },
  {
    path: "/facilitation",
    title: "Facilitation",
  },
  {
    path: "/learningthroughplay",
    title: "Learning Through Play",
  },
  {
    path: "/blog",
    title: "Blog",
  },
  {
    path: "/about",
    title: "About",
  },
  {
    path: "/getbricks",
    title: "Get bricks",
  },
];

const Layout = ({
  children,
  title,
  description,
  additionalStyle,
  breadcrumbsMap,
  showBreadcrumbs = true,
  brailleTextImage = null,
  escapeMainLayout = false,
  imageUrl,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mainHidden, setMainHidden] = useState(false);

  const onClickOpenMenu = open => {
    setMenuOpen(open);

    /*
      When the mobile menu is open, we remove everything except
      the header and mobile menu so it can't get keyboard focus.
    */
    if (open) {
      setTimeout(() => {
        setMainHidden(true);
      }, MENU_TRANSITION_TIME);
    } else {
      setMainHidden(false);
    }
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title={title} description={description} imageUrl={imageUrl} />
      <a
        sx={{
          display: "block",
          position: "absolute",
          top: "-4rem",
          left: "1rem",
          bg: "#FFFFFF",
          color: "lbbRed",
          padding: "1rem",
          opacity: 0,
          ":focus": {
            top: 0,
            opacity: 1,
          },
          transition: "top 200ms, opacity 100ms",
        }}
        href="#main"
      >
        Skip to content
      </a>
      <Header
        links={links}
        onClickHome={() => onClickOpenMenu(false)}
        onClickMenuButton={() => onClickOpenMenu(!menuOpen)}
        menuOpen={menuOpen}
      />
      <div sx={{ position: "relative" }}>
        <div
          sx={{
            display: ["block", null, "none"],
            position: "absolute",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            top: 0,
            left: menuOpen ? "0" : "-110vw",
            transition: `left ${MENU_TRANSITION_TIME}ms`,
            zIndex: 1,
          }}
        >
          <MobileMenu
            links={links}
            onClickLink={() => onClickOpenMenu(false)}
            isOpen={menuOpen}
          />
        </div>
        <div sx={{ display: [mainHidden ? "none" : "block", null, "block"] }}>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: [
                `40px ${MOBILE_HORIZONTAL_PADDING}px`,
                null,
                `40px ${DESKTOP_HORIZONTAL_PADDING}px`,
              ],
            }}
          >
            {showBreadcrumbs && (
              <div sx={{ flex: 1 }}>
                <Breadcrumbs breadcrumbsMap={breadcrumbsMap} />
              </div>
            )}
            {brailleTextImage && (
              <img
                src={brailleTextImage.url}
                alt={`${brailleTextImage.alt} written in Braille`}
                sx={{ display: ["none", null, "block"], height: "2rem" }}
              />
            )}
          </div>
          <div
            sx={{
              padding: [
                `40px ${MOBILE_HORIZONTAL_PADDING}px`,
                null,
                `40px ${DESKTOP_HORIZONTAL_PADDING}px`,
              ],
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...additionalStyle,
            }}
          >
            {escapeMainLayout ? (
              children
            ) : (
              <main
                sx={{
                  width: "100%",
                  margin: "0 auto",
                  maxWidth: `${DESKTOP_MAX_WIDTH}px`,
                }}
              >
                {/*
              <div
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: ["60px", "80px"],
                  maxWidth: `${DESKTOP_MAX_WIDTH}px`,
                  "@media print": {
                    display: "none",
                  },
                }}
              ></div>*/}
                <div id="main">{children}</div>
              </main>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
