/** @jsx jsx */
import { jsx } from "theme-ui";

/*
  Use this component if you want an image to be inverted
  when Smart Invert in iOS Safari is enabled.
*/

const InvertableImage = props => (
  <div
    sx={{
      "@media (inverted-colors: inverted)": {
        filter: "invert(100%)",
      },
    }}
  >
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img {...props} />
  </div>
);

export default InvertableImage;
