/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { Location } from "@reach/router";

import ChevronIcon from "../assets/icons/chevron.svg";

const staticBreadcrumbsMap = {
  activities: "Activities",
  facilitation: "Facilitation",
  learningthroughplay: "Learning through play",
  about: "About",
  getbricks: "Get bricks",
  search: "Search",
  privacypolicy: "Privacy Policy",
  cookiepolicy: "Cookie Policy",
  accessibility: "Accessibility",
  login: "Login",
  "my-profile": "My profile",
  blog: "Blog",
};

const Breadcrumbs = ({ breadcrumbsMap }) => (
  <Location>
    {({ location }) => {
      const mergedBreadcrumbsMap = {
        ...staticBreadcrumbsMap,
        ...breadcrumbsMap,
      };

      const segments = location.pathname
        .split("/")
        .filter(segment => segment.length > 0);

      const breadcrumbs = [
        {
          name: "Home",
          url: "",
        },
        ...segments.map((segment, index) => ({
          name: mergedBreadcrumbsMap[segment],
          url: segments.slice(0, index + 1).join("/"),
        })),
      ].filter(({ name }) => Boolean(name));

      return (
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <nav
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
            aria-label="Breadcrumbs"
          >
            {breadcrumbs.map(({ name, url }, index) => (
              <div
                key={url}
                sx={{
                  // On mobile, we only show the second last breadcrumb
                  display: [
                    index === breadcrumbs.length - 2 ? "block" : "none",
                    "block",
                  ],
                }}
              >
                <div
                  sx={{
                    display: [
                      "inline-block",
                      index > 0 ? "inline-block" : "none",
                    ],
                    background: `url(${ChevronIcon}) no-repeat 50% 50% / contain`,
                    width: "12px",
                    height: "12px",
                    marginLeft: [0, "1rem"],
                    marginRight: "1rem",
                    transform: ["rotate(-180deg)", "rotate(0)"],
                  }}
                />
                <Link
                  sx={{
                    textDecoration: "none",
                    color: "text",
                    ":hover": {
                      color: "lbbRed",
                    },
                    transition: "color 300ms",
                  }}
                  to={`/${url}`}
                >
                  {name}
                </Link>
              </div>
            ))}
          </nav>
        </div>
      );
    }}
  </Location>
);

export default Breadcrumbs;
