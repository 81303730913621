/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Link, useStaticQuery, graphql } from "gatsby";
import InvertableImage from "./InvertableImage";
import {
  DESKTOP_MAX_WIDTH,
  DESKTOP_HORIZONTAL_PADDING,
  MOBILE_HORIZONTAL_PADDING,
} from "../utils/Constants";

import LegoFoundationLogo from "../assets/images/lego_foundation_logo.png";
import FacebookLogo from "../assets/images/facebook_logo.svg";
import ContactIcon from "../assets/icons/contact.svg";
import FooterImage from "../assets/images/footer.png";
import YoutubeLogo from "../assets/images/youtube-logo.svg";

const Footer = () => {
  const { allFacilitationArticle: facilitationArticles } = useStaticQuery(
    graphql`
      query {
        allFacilitationArticle {
          nodes {
            name
            url
          }
        }
      }
    `
  );

  const LinkSections = [
    {
      topLevelTitle: "Home",
      topLevelUrl: "/",
      subPages: [],
    },
    {
      topLevelTitle: "Activities",
      topLevelUrl: "/activities",
      subPages: [
        {
          name: "Manipulation",
          url: "/activities/manipulation",
        },
        {
          name: "Orientation",
          url: "/activities/orientation",
        },
        {
          name: "Constellation",
          url: "/activities/constellation",
        },
        {
          name: "Characters",
          url: "/activities/characters",
        },
        {
          name: "Literacy",
          url: "/activities/literacy",
        },
        {
          name: "Numeracy",
          url: "/activities/numeracy",
        },
      ],
    },
    {
      topLevelTitle: "Facilitation",
      topLevelUrl: "/facilitation",
      subPages: facilitationArticles.nodes.map(({ name, url }) => ({
        name,
        url,
      })),
    },
    {
      topLevelTitle: "Learning through play",
      topLevelUrl: "/learningthroughplay",
      subPages: [
        {
          name: "Cognitive",
          url: "/learningthroughplay/cognitive",
        },
        {
          name: "Creative",
          url: "/learningthroughplay/creative",
        },
        {
          name: "Emotional",
          url: "/learningthroughplay/emotional",
        },
        {
          name: "Physical",
          url: "/learningthroughplay/physical",
        },
        {
          name: "Social",
          url: "/learningthroughplay/social",
        },
      ],
    },
    {
      topLevelTitle: "About",
      topLevelUrl: "/about",
      subPages: [],
    },
    {
      topLevelTitle: "Get bricks",
      topLevelUrl: "/getbricks",
      subPages: [],
    },
  ];

  return (
    <footer
      sx={{
        bg: "lbbRed",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        "@media print": {
          display: "none",
        },
      }}
    >
      <div
        sx={{
          padding: [
            `80px ${MOBILE_HORIZONTAL_PADDING}px`,
            null,
            `80px ${DESKTOP_HORIZONTAL_PADDING}px`,
          ],
          width: "100%",
        }}
      >
        <div
          sx={{
            maxWidth: `${DESKTOP_MAX_WIDTH}px`,
            width: "100%",
            margin: "0 auto",
          }}
        >
          <InvertableImage
            src={LegoFoundationLogo}
            alt="LEGO Foundation Logo"
            sx={{
              height: "1.5rem",
              objectFit: "contain",
            }}
          />
          <nav
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
              marginTop: "3rem",
            }}
            aria-label="Footer navigation"
          >
            {LinkSections.map(({ topLevelTitle, topLevelUrl, subPages }) => (
              <div key={topLevelTitle} sx={{ flex: 1, marginRight: "2rem" }}>
                <Link
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    textDecoration: "none",
                    marginBottom: ["25px", "35px"],
                  }}
                  to={topLevelUrl}
                >
                  <Styled.h3 sx={{ fontSize: 0, lineHeight: 0 }}>
                    {topLevelTitle}
                  </Styled.h3>
                </Link>
                <ul
                  sx={{
                    listStyleType: "none",
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {subPages.map(({ name, url }) => (
                    <li key={name}>
                      <Link
                        sx={{
                          display: "block",
                          textDecoration: "none",
                          color: "#FFFFFF",
                          paddingBottom: "1rem",
                        }}
                        to={url}
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                width: ["auto", "100%", "auto"],
                marginTop: ["2rem", null, 0],
              }}
            >
              <div
                sx={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <a href="https://www.facebook.com/groups/2093544860690025">
                  <InvertableImage
                    src={FacebookLogo}
                    alt="Link to LEGO Braille Bricks Facebook Group"
                    sx={{
                      width: "31px",
                      height: "31px",
                      marginBottom: ["25px", "35px"],
                      "@media (inverted-colors: inverted)": {
                        filter: "invert(100%)",
                      },
                    }}
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCqeu5UnSaa-8CmcotDKiciQ">
                  <InvertableImage
                    src={YoutubeLogo}
                    alt="Link to LEGO Braille Bricks Youtube channel"
                    sx={{
                      width: "31px",
                      height: "31px",
                      marginBottom: ["25px", "35px"],
                      "@media (inverted-colors: inverted)": {
                        filter: "invert(100%)",
                      },
                    }}
                  />
                </a>
              </div>
              <Link
                sx={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  marginBottom: "1rem",
                }}
                to="/cookiepolicy/"
              >
                Cookie policy
              </Link>
              <Link
                sx={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  marginBottom: "1rem",
                }}
                to="/privacypolicy/"
              >
                Privacy policy
              </Link>
              <Link
                sx={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  marginBottom: "1rem",
                }}
                to="/accessibility/"
              >
                Accessibility
              </Link>
              <Link
                sx={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  marginBottom: "1rem",
                }}
                to="/community-guidelines/"
              >
                Community guidelines
              </Link>
              <a
                href="mailto:info@legobraillebricks.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#FFFFFF",
                }}
              >
                <span sx={{ marginRight: "1rem" }}>
                  <InvertableImage src={ContactIcon} alt="" />
                </span>
                Contact us
              </a>
            </div>
          </nav>
          <div sx={{ color: "#FFFFFF", marginTop: "40px" }}>
            LEGO, the LEGO logo and DUPLO are trademarks of the LEGO Group.
            <br />
            ©2020 The LEGO Group
          </div>
        </div>
      </div>
      <img src={FooterImage} alt="" sx={{ width: "100%", height: "auto" }} />
    </footer>
  );
};

export default Footer;
