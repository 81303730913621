/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link as L } from "gatsby";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import InvertableImage from "./InvertableImage";
import MenuButton from "./MenuButton";
import {
  DESKTOP_MAX_WIDTH,
  DESKTOP_HORIZONTAL_PADDING,
  MOBILE_HORIZONTAL_PADDING,
} from "../utils/Constants";

import LogoImage from "../assets/images/logo.png";

export default function Header({
  links,
  onClickHome,
  onClickMenuButton,
  menuOpen,
}) {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  async function goToLogin() {
    try {
      await instance.loginRedirect({});
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <header
      sx={{
        bg: "lbbRed",
        height: "4rem",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: [
          `0 ${MOBILE_HORIZONTAL_PADDING}px`,
          null,
          `0 ${DESKTOP_HORIZONTAL_PADDING}px`,
        ],
        "@media print": {
          display: "none",
        },
      }}
    >
      <div
        sx={{
          maxWidth: `${DESKTOP_MAX_WIDTH}px`,
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <nav
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          aria-label="Header"
        >
          <Link to="/" onClick={onClickHome} partiallyActive={false}>
            <InvertableImage
              sx={{
                objectFit: "contain",
                height: "40px",
              }}
              src={LogoImage}
              alt="Go to Home page"
            />
          </Link>
          {links.map(({ path, title }) => (
            <Link key={path} to={path}>
              {title}
            </Link>
          ))}
          {isAuthenticated ? (
            <Link to="/my-profile">My profile</Link>
          ) : (
            <ButtonLink type="button" onClick={goToLogin}>
              Login
            </ButtonLink>
          )}
        </nav>
        <MenuButton
          open={menuOpen}
          onClick={() => {
            onClickMenuButton();
          }}
        />
      </div>
    </header>
  );
}

const Link = props => (
  <L
    sx={{
      display: ["none", null, "block"],
      color: "#FFFFFF",
      paddingTop: "6px",
      paddingBottom: "4px",
      textDecoration: "none",
      borderBottom: "2px solid var(--theme-ui-colors-lbbRed)",
    }}
    activeStyle={{ borderColor: "white" }}
    partiallyActive
    {...props}
  />
);

const ButtonLink = props => (
  <button
    sx={{
      backgroundColor: "transparent",
      display: ["none", null, "block"],
      font: "inherit",
      border: "none",
      cursor: "pointer",
      color: "#FFFFFF",
      paddingTop: "6px",
      paddingBottom: "4px",
      textDecoration: "none",
      borderBottom: "2px solid var(--theme-ui-colors-lbbRed)",
    }}
    {...props}
  />
);
