/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link as L } from "gatsby";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const MobileMenu = ({ links, onClickLink, isOpen }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  async function goToLogin() {
    try {
      await instance.loginRedirect({});
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <nav
      sx={{
        display: "flex",
        flexDirection: "column",
        bg: "lbbRed",
        width: "100vw",
        height: "100vh",
        padding: "1rem",
      }}
    >
      <ul sx={{ listStyleType: "none", padding: 0 }} aria-hidden={!isOpen}>
        {links.map(({ path, title }) => (
          <li key={path}>
            <Link to={path} tabIndex={isOpen ? 0 : -1} onClick={onClickLink}>
              {title}
            </Link>
          </li>
        ))}
        <li>
          {isAuthenticated ? (
            <Link to="/my-profile">My profile</Link>
          ) : (
            <ButtonLink type="button" onClick={goToLogin}>
              Login
            </ButtonLink>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default MobileMenu;

const Link = props => (
  <L
    sx={{
      display: "block",
      width: "max-content",
      color: "#FFFFFF",
      paddingTop: "6px",
      paddingBottom: "4px",
      fontSize: "26px",
      textDecoration: "none",
      borderBottom: "2px solid transparent",
      marginBottom: "1rem",
    }}
    activeStyle={{ borderColor: "#FFFFFF" }}
    {...props}
  />
);

const ButtonLink = props => (
  <button
    sx={{
      display: "block",
      width: "max-content",
      color: "#FFFFFF",
      padding: "6px 0 4px",
      font: "inherit",
      fontSize: "26px",
      textDecoration: "none",
      marginBottom: "1rem",
      backgroundColor: "transparent",
      border: "none",
      borderBottom: "2px solid transparent",
      cursor: "pointer",
    }}
    {...props}
  />
);
