/** @jsx jsx */
import { jsx } from "theme-ui";

import MenuOpenIcon from "../assets/icons/menu_open.svg";
import MenuCloseIcon from "../assets/icons/menu_close.svg";

const MenuButton = ({ open, onClick }) => (
  <button
    sx={{
      display: ["flex", null, "none"],
      flexDirection: "row",
      alignItems: "center",
      border: "none",
      background: "transparent",
      color: "#FFFFFF",
      fontFamily: "body",
      fontSize: 1,
      cursor: "pointer",
    }}
    onClick={onClick}
    aria-label={open ? "Close menu" : "Open menu"}
  >
    {open ? "Close" : "Menu"}
    <div
      sx={{
        background: `url(${
          open ? MenuCloseIcon : MenuOpenIcon
        }) transparent no-repeat 50% 50% / contain`,
        width: "30px",
        height: "23px",
        marginLeft: "1rem",
      }}
    />
  </button>
);

export default MenuButton;
